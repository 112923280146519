import React, { useMemo, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useExchangeInfoStore } from "src/store";
import { accountApi } from "src/api";
import AccountSelect from "src/component/AccountSelect/AccountSelect";
import ExchangeSelect from "src/component/ExchangeSelect/ExchangeSelect";
import { Flex, Spinner, Text, CheckboxGroup, Checkbox, Stack } from "@chakra-ui/react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { TradeType } from "src/api/types";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Balance {
  name: string;
  free: number;
  lock: number;
  precision: number;
}

interface BalanceReportItem {
  exchangeAccountId: number;
  createdAtHour: string;
  balances: Balance[] | null;
}

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
    yAxisID: string;
    fill?: boolean;
  }[];
}

const BalanceReport: React.FC = () => {
  const selectedExchangeAccountId = useExchangeInfoStore((state) => state.selectedExchangeAccountId);

  const balanceDataQuery = useQuery<BalanceReportItem[], Error>({
    queryKey: ["balances-report", selectedExchangeAccountId],
    queryFn: async () => {
      const result = await accountApi.fetchAccountBalanceHistory({
        exchangeAccountId: Number(selectedExchangeAccountId),
      });
      return Array.isArray(result) ? result : [];
    },
    enabled: !!selectedExchangeAccountId,
    staleTime: 1000 * 60 * 5,
  });

  const chartData: ChartData = useMemo(() => {
    if (!balanceDataQuery.data || balanceDataQuery.data.length === 0) {
      return { labels: [], datasets: [] };
    }

    const queryResult = balanceDataQuery.data;
    const labels = queryResult
      .map((item) => item.createdAtHour)
      .filter((label): label is string => typeof label === "string");

    const balanceNames = [
      ...new Set(queryResult.flatMap((item) => item.balances?.map((balance) => balance.name) || [])),
    ];

    if (balanceNames.length === 0) {
      return { labels, datasets: [] };
    }

    const generateColor = (index: number): string => {
      const hue = (index * 137.5) % 360;
      return `hsl(${hue}, 65%, 55%)`;
    };

    const colorMap: Record<string, { borderColor: string; backgroundColor: string }> = {};
    balanceNames.forEach((name, index) => {
      const color = generateColor(index);
      colorMap[name] = {
        borderColor: color,
        backgroundColor: color.replace("hsl", "hsla").replace(")", ", 0.5)"),
      };
    });

    const datasets = balanceNames.map((name, index) => {
      const data = queryResult.map((item) => {
        const balance = item.balances?.find((b) => b.name === name);
        return balance ? Math.round(balance.free + balance.lock) : 0;
      });

      return {
        label: name,
        data,
        borderColor: colorMap[name].borderColor,
        backgroundColor: colorMap[name].backgroundColor,
        yAxisID: `y${index}`,
        fill: false,
      };
    });

    return {
      labels,
      datasets,
    };
  }, [balanceDataQuery.data]);

  const { labels, datasets } = chartData;

  const [selectedDatasets, setSelectedDatasets] = useState<string[]>([]);

  useEffect(() => {
    if (datasets.length > 0 && selectedDatasets.length === 0) {
      setSelectedDatasets([datasets[0].label]);
    }
  }, [datasets, selectedDatasets]);

  const filteredDatasets = useMemo(() => {
    return datasets.filter((dataset) => selectedDatasets.includes(dataset.label));
  }, [datasets, selectedDatasets]);

  const dynamicOptions = useMemo(() => {
    const scalesConfig: Record<string, any> = {};

    filteredDatasets.forEach((dataset, index) => {
      const axisId = dataset.yAxisID;
      if (scalesConfig[axisId]) return;
      scalesConfig[axisId] = {
        type: "linear",
        display: true,
        position: index % 2 === 0 ? "left" : "right",
        grid: {
          drawOnChartArea: index === 0,
        },
        title: {
          display: true,
          text: dataset.label,
        },
      };
    });

    return {
      responsive: true,
      interaction: {
        mode: "index" as const,
        intersect: false,
      },
      stacked: false,
      plugins: {
        legend: {
          position: "top" as const,
        },
        title: {
          display: true,
          text: "Balances Report",
        },
      },
      scales: scalesConfig,
    };
  }, [filteredDatasets]);

  if (balanceDataQuery.isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100vh" flexDir={"column"} width="100%">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (balanceDataQuery.isError) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100vh">
        <Text color="red.500">错误: {balanceDataQuery.error.message || "未知错误"}</Text>
      </Flex>
    );
  }

  if (labels.length === 0 || datasets.length === 0) {
    return (
      <Flex alignItems="flex-start" flexDir={"column"} width={"35%"} p={4} boxSizing="border-box" gap={2}>
        <ExchangeSelect tradeType={TradeType.SPOT} />
        <AccountSelect />
      </Flex>
    );
  }

  return (
    <Flex
      alignItems="flex-start"
      flexDir={"column"}
      width={"100%"}
      p={4}
      boxSizing="border-box"
      justifyContent="space-between"
    >
      <Flex justifyContent={"space-between"} width={"70%"}>
        <Flex width="calc(50% - 20px)" maxW="600px" gap={2} flexDir="column">
          <ExchangeSelect tradeType={TradeType.SPOT} />
          <AccountSelect />
        </Flex>

        <CheckboxGroup
          colorScheme="blue"
          value={selectedDatasets}
          onChange={(values) => setSelectedDatasets(values as string[])}
        >
          <Stack direction="row" flexWrap="wrap" spacing={4}>
            {datasets.map((d) => (
              <Checkbox key={d.label} value={d.label}>
                {d.label}
              </Checkbox>
            ))}
          </Stack>
        </CheckboxGroup>
      </Flex>

      <Line options={dynamicOptions} data={{ labels, datasets: filteredDatasets }} />
    </Flex>
  );
};

export default BalanceReport;
