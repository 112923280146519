import { api, ApiResponse } from "./base";
import { PumpOrSmashParams, TradeTokenParams, TradeDirection } from "./types";

export const tradeToken = async (payload: { accountId: number; amount: string; pairId: number; sell?: boolean }) => {
  const res = await api.post<ApiResponse<any>>(`/orders/exchangeAccounts/${payload.accountId}`, {
    side: payload.sell ? TradeDirection.Sell : TradeDirection.Buy,
    baseAmount: payload.amount,
    pairId: payload.pairId,
  });
  return res.data.data;
};

export const tradeTokenV2 = async (payload: TradeTokenParams) => {
  const res = await api.post<ApiResponse<any>>(`/v2/orders/exchangeAccounts/create`, payload);
  return res.data.data;
};

export const createSingleVolume = async (payload: {
  exchangeAccountId: number;
  pairId: number;
  totalAmount: number;
  targetPrice?: number;
}) => {
  const ret = await api.post<ApiResponse<any>>("/v2/orders/exchangeAccounts/singleVolume", payload);
  return ret.data.data;
};

export const createTPSL = async (payload: {
  exchangeAccountId: number;
  pairId: number;
  price: number;
  side: "sell" | "buy";
  quantity: number;
  stopPrice: number;
}) => {
  const ret = await api.post<ApiResponse<any>>("/v2/orders/exchangeAccounts/createTPSL", payload);
  return ret.data.data;
};

export const pumpOrSmashV2 = async (payload: PumpOrSmashParams) => {
  const res = await api.post<ApiResponse<any>>(`/v2/orders/multiExCreate`, payload);
  return res.data.data;
};
