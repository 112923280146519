import { Flex, Text } from "@chakra-ui/react";
import { Formik, Field } from "formik";
import { Form } from "react-router-dom";
import { strategiesApi } from "src/api";
import { StrategyResponse, StrategyType, TPSLExampleType, UpdateStrategyParams } from "src/api/types";
import CButton from "../CButton/CButton";
import styles from "./StrategyDetail.module.scss";
import * as Yup from "yup";
import { useMessage } from "src/hook/useMessage";
import CommonAutoSellForm from "../StrategyForms/CommonAutoSellForm";
import { ErrorMessage } from "src/utils/ErrorMessage";
import CInput from "../CInput/CInput";

type Props = {
  strategy: StrategyResponse<TPSLExampleType>;
  onUpdated?: () => void;
};

export default function TPSLAutoDetail(props: Props) {
  const { strategy, onUpdated } = props;
  const message = useMessage();

  return (
    <Formik
      initialValues={{
        strategyName: strategy.strategyName,
        repeatIntervalMsMin: strategy.repeatIntervalMsMin,
        repeatIntervalMsMax: strategy.repeatIntervalMsMax,
        preference: {
          type: strategy.preference.type,
          triggerPrice10: strategy.preference.triggerPrice10,
          buy10: strategy.preference.buy10,
          price10: strategy.preference.price10,
          qta10: strategy.preference.qta10,
        },
      }}
      validationSchema={Yup.object({
        preference: Yup.object().shape({
          triggerPrice10: Yup.string().required("Required"),
          price10: Yup.number().required("Required"),
          qta10: Yup.number().required("Required"),
        }),
        repeatIntervalMsMin: Yup.number().required("Required").min(1000, "Minimum value is 1000"),
        repeatIntervalMsMax: Yup.number().required("Required").min(1000, "Minimum value is 1000"),
      })}
      onSubmit={async (values) => {
        const payload: UpdateStrategyParams<TPSLExampleType> = {
          strategyId: strategy.id,
          strategyName: values.strategyName,
          repeatIntervalMsMin: values.repeatIntervalMsMin,
          repeatIntervalMsMax: values.repeatIntervalMsMax,
          preference: {
            type: values.preference.type,
            triggerPrice10: values.preference.triggerPrice10,
            buy10: values.preference.buy10,
            price10: Number(values.preference.price10),
            qta10: Number(values.preference.qta10),
          },
        };

        try {
          const result = await strategiesApi.updateStrategyV2(payload);
          message.success("更新成功");
          onUpdated && onUpdated();
        } catch (error) {
          message.detailedError(<ErrorMessage error={error} />);
        }
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <Form className={styles.form}>
            <Flex width="calc(100% - 20px)" flexDirection="column" gap={2}>
              <Field name="strategyName" render={({ field }) => <CInput {...field} label="策略名称" type="text" />} />
              <Field
                name="preference.triggerPrice10"
                render={({ field }) => <CInput {...field} label="触发价格" type="text" />}
              />

              <Field
                name="preference.price10"
                render={({ field }) => <CInput {...field} label="下单价格" type="number" />}
              />

              <Field name="preference.qta10" render={({ field }) => <CInput {...field} label="数量" type="number" />} />

              <Field
                name="repeatIntervalMsMin"
                render={({ field }) => <CInput {...field} label="重复间隔(最小值)" type="number" />}
              />

              <Field
                name="repeatIntervalMsMax"
                render={({ field }) => <CInput {...field} label="重复间隔(最大值)" type="number" />}
              />

              <CButton name="保存" type="button" onClick={submitForm} />
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
}
