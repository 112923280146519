import { Button, Flex, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { orderApi, strategiesApi } from "src/api";
import { timestampToFmt } from "src/utils/date";
import { useExchangeInfoStore } from "src/store";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { TradeOrderType } from "src/api/types";
import CModal from "../CModal/CModal";
import { VscScreenFull } from "react-icons/vsc";
import { Pagination } from "../CTable/Pagination";
import CTable from "../CTable/Ctable";

type Props = {
  stragegyId?: number;
  hideFullScreenButton?: boolean;
  paginated?: boolean;
};

export default function TradeOrder(props: Props) {
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const location = useLocation();
  const exchangeAccountId = useExchangeInfoStore((state) => state.selectedExchangeAccountId);
  const isSelf = new URLSearchParams(location.search).get("isSelf");
  const pairs = useExchangeInfoStore((state) => state.spotPairs);
  const [completeOrders, setCompleteOrders] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const cols = [
    {
      key: "createdAt",
      render: (order: any) => {
        return timestampToFmt(new Date(order.createdAt).getTime());
      },
      title: "下单时间",
    },
    {
      key: "pairId",
      render: (order: any) => {
        const pairId = order.pairId;
        const target = pairs.find((pair) => pair.id === pairId);
        return `${target?.base}-${target?.quote}`;
      },
      title: "交易对",
    },
    {
      key: "side",
      title: "方向",
    },
    {
      key: "expectedPrice",
      title: "成交价格",
    },
    {
      key: "price",
      title: "委托价格",
    },
    {
      key: "executedAmount",
      title: "成交数量",
    },
    {
      key: "fee",
      title: "手续费",
      render: (order: any) => {
        return `${order.fee} ${order.feeCurrency}`;
      },
    },
    {
      key: "baseAmount",
      render: (order: any) => {
        return order.baseAmount;
      },
      title: "数量",
    },
    {
      key: "null",
      render: (order: any) => {
        const price = Number(order.expectedPrice);
        const amount = Number(order.executedAmount);
        if (Number.isNaN(price) || Number.isNaN(amount)) {
          return "";
        }
        return (price * amount).toFixed(2);
      },
      title: "总金额",
    },
    {
      key: "type",
      title: "类型",
    },
    // {
    //   key: "status",
    //   title: "状态",
    // },
    {
      key: "source",
      title: "来源",
    },
    {
      key: "sourceId",
      title: "来源ID",
    },
    {
      key: "id",
      title: "订单ID",
    },
    {
      key: "accountName",
      title: "交易员",
    },
  ];

  const orderQuery = useQuery({
    queryKey: ["orders", exchangeAccountId, isSelf, props.stragegyId],
    queryFn: () => {
      console.log("exchangeAccountId", exchangeAccountId, "props.stragegyId", props.stragegyId);
      if (props.stragegyId) {
        return strategiesApi.fetchOrdersOfStrategy(props.stragegyId, true);
      }
      console.log("ssss1", exchangeAccountId);
      return orderApi.fetchOrdersV2(exchangeAccountId, "Manual");
    },
    refetchInterval: 5000,
  });

  const processedOrders = useMemo(() => {
    if (!orderQuery.data) return [];

    let orders = orderQuery.data.map((order: TradeOrderType) => ({
      ...order,
      isGreen: order.side.toLowerCase() === "buy",
      isRed: order.side.toLowerCase() === "sell",
    }));

    // 根据 completeOrders 过滤订单
    if (completeOrders) {
      orders = orders.filter((order) => order.executedAmount > 0);
    }

    return orders;
  }, [orderQuery.data, completeOrders]);

  const totalCount = processedOrders.length;

  // 计算总页数
  const totalPages = Math.ceil(totalCount / pageSize);

  // 当 totalPages 变化时，检查当前页码是否超出范围
  useEffect(() => {
    if (page > totalPages && totalPages > 0) {
      setPage(totalPages);
    }
  }, [page, totalPages]);

  const paginatedOrders = useMemo(() => {
    if (props.paginated) {
      const start = (page - 1) * pageSize;
      const end = page * pageSize;
      return processedOrders.slice(start, end);
    }
    return processedOrders;
  }, [processedOrders, page, pageSize, props.paginated]);

  const toggleCompleteOrders = () => {
    setCompleteOrders((prev) => !prev);
    setPage(1); // 切换筛选条件时，重置页码为1
  };

  return (
    <>
      <Flex width="100%" flexDirection="column">
        <Flex width="100%" justifyContent="space-between">
          <Text fontSize="20px" fontWeight={600}>
            订单历史
          </Text>
          <Flex gap={4} alignItems="center">
            {!props.hideFullScreenButton && (
              <VscScreenFull size="24px" cursor="pointer" onClick={() => setIsOrderModalOpen(true)} />
            )}
            <Button size="sm" onClick={() => orderQuery.refetch()}>
              刷新
            </Button>
            <Button size="sm" onClick={toggleCompleteOrders}>
              {completeOrders ? "只看成交" : "全部订单"}
            </Button>
          </Flex>
        </Flex>
        <CTable items={paginatedOrders} cols={cols} showHeader />
        {props.paginated && (
          <Pagination
            total={totalCount}
            pageSize={pageSize}
            currentPage={page}
            onPageChange={(page: number) => {
              setPage(page);
            }}
          />
        )}
      </Flex>

      <CModal size="6xl" title="订单详情" isOpen={isOrderModalOpen} onClose={() => setIsOrderModalOpen(false)}>
        <TradeOrder hideFullScreenButton />
      </CModal>
    </>
  );
}
