import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  InputGroup,
  InputLeftElement,
  Spinner,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import CInput from "../CInput/CInput";
import CSwitch from "../CSwitch/CSwitch";
import { useExchangeInfoStore } from "src/store";
import { traderApi } from "src/api";
import { PairItem, TradeDirection } from "src/api/types";
import { useRef, useState } from "react";
import { useMessage } from "src/hook/useMessage";
import { ErrorMessage } from "src/utils/ErrorMessage";
import styles from "./Limit.module.scss";
import React from "react";

type TpSlOrderFromProps = {
  pair: PairItem;
};

type FormValues = {
  side: "sell" | "buy";
  quantity: string;
  stopPrice: string;
  price: string;
};

const TpSlOrderFrom: React.FC<TpSlOrderFromProps> = ({ pair }) => {
  const store = useExchangeInfoStore();
  const message = useMessage();
  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  // 使用 ref 来跟踪最后更新的字段
  const lastUpdatedFieldRef = useRef<keyof FormValues | null>(null);

  // 定义表单
  const formik = useFormik<FormValues>({
    initialValues: {
      side: "sell",
      quantity: "",
      stopPrice: "",
      price: "",
    },
    validationSchema: Yup.object({
      quantity: Yup.string()
        .matches(/^\d*\.?\d*$/, "必须为数字")
        .required("必填字段"),
      stopPrice: Yup.string().matches(/^\d*\.?\d*$/, "必须为数字"),
      price: Yup.string().required("必填字段"),
      // .test("is-valid-price", function (value) {
      //   const { stopPrice, side } = this.parent; // 从表单中获取 stopPrice 和 side
      //   if (!stopPrice || !value) {
      //     return this.createError({
      //       message: "下单价格和触发价格不能为空",
      //     });
      //   }

      //   if (side === "buy") {
      //     // 买入方向：下单价格必须小于等于触发价格
      //     if (Number(value) > Number(stopPrice)) {
      //       return this.createError({
      //         message: "买入时，下单价格必须小于等于触发价格",
      //       });
      //     }
      //   } else if (side === "sell") {
      //     // 卖出方向：下单价格必须大于等于触发价格
      //     if (Number(value) < Number(stopPrice)) {
      //       return this.createError({
      //         message: "卖出时，下单价格必须大于等于触发价格",
      //       });
      //     }
      //   }

      //   return true; // 如果校验通过
      // }),
    }),
    onSubmit: onOpen, // 打开确认对话框
  });

  const handleActualSubmit = async (values: FormValues) => {
    if (isLoading) return;
    if (!store.selectedExchangeAccountId || store.selectedExchangeAccountId === -1) {
      alert("请选择交易账户");
      return;
    }
    const payload = {
      exchangeAccountId: Number(store.selectedExchangeAccountId),
      pairId: Number(pair.id),
      side: values.side,
      quantity: Number(values.quantity),
      stopPrice: Number(values.stopPrice),
      price: Number(values.price),
    };
    console.log("TPSL payload", payload);
    try {
      setIsLoading(true);
      const result = await traderApi.createTPSL(payload);
      console.log("createTPSLresult", result);
      message.success("下单成功");
    } catch (error) {
      message.detailedError(<ErrorMessage error={error} />);
      console.error("TPSL error", error);
      console.error("TPSL params: ", payload);
    } finally {
      setIsLoading(false);
    }
  };

  // 处理输入变化，只设置 lastUpdatedFieldRef.current，不调用 setFieldValue
  const handleInputChange = (field: keyof FormValues) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // 去除多余的前导零，但保留单个零和以 "0." 开头的数字

    value = value.replace(/^0+(\d)/, "$1");
    if (!/^\d*\.?\d*$/.test(value)) {
      return;
    }

    formik.setFieldValue(field, value);
    lastUpdatedFieldRef.current = field;
  };

  const handleSideChange = () => {
    const towardsSide = formik.values.side === "buy" ? "sell" : "buy";
    formik.setFieldValue("side", towardsSide);
  };
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Flex flexWrap="wrap" gap={1} justifyContent="space-between">
          <Flex alignItems="center" gap={2} justifyContent={"space-between"} width={"270px"}>
            <Text whiteSpace="nowrap">下单方向:</Text>
            <Text>买单</Text>
            <Switch isChecked={formik.values.side === "sell"} onChange={handleSideChange} />
            <Text>卖单</Text>
          </Flex>
          <CInput
            classname={styles.input}
            label="下单数量"
            name="下单数量"
            type="text"
            placeholder="下单数量"
            onChange={handleInputChange("quantity")}
          />
          <CInput
            classname={styles.input}
            label="触发价"
            name="stopPrice"
            type="text"
            placeholder="触发价"
            onChange={handleInputChange("stopPrice")}
            rightIndicator="U"
          />
          <CInput
            classname={styles.input}
            label="下单价"
            name="price"
            type="text"
            placeholder="下单价"
            onChange={handleInputChange("price")}
            rightIndicator="U"
          />
        </Flex>

        <Flex justifyContent="center" mt={4}>
          <Flex width="auto">
            <InputGroup>
              {isLoading && (
                <InputLeftElement pointerEvents="none">
                  <Spinner size="sm" color="gray.300" />
                </InputLeftElement>
              )}
              <Button
                type="submit"
                width="200px"
                bgColor={formik.values.side === "buy" ? "teal" : "#f08080"}
                color="white"
                cursor="pointer"
                disabled={!formik.isValid || isLoading}
              >
                {formik.values.side === "buy" ? "买入" : "卖出"}
              </Button>
            </InputGroup>
          </Flex>
        </Flex>
      </form>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              确认交易
            </AlertDialogHeader>

            <AlertDialogBody>确定下止盈止损{formik.values.side === "buy" ? "买" : "卖"}单吗？</AlertDialogBody>
            <AlertDialogBody>
              当价格
              <span
                style={{
                  color: formik.values.side === "buy" ? "red" : "green",
                }}
              >
                {formik.values.side === "buy" ? "上涨" : "下跌"}
              </span>
              至或
              <span
                style={{
                  color: formik.values.side === "buy" ? "red" : "green",
                }}
              >
                {formik.values.side === "buy" ? "高于" : "低于"}
              </span>
              <span style={{ color: "red" }}>{formik.values.stopPrice}</span>
              USDT, 则触发以
              <span style={{ color: "red" }}>{formik.values.price}</span>
              USDT的价格
              <span
                style={{
                  color: formik.values.side === "buy" ? "red" : "green",
                }}
              >
                {formik.values.side === "buy" ? "买入" : "卖出"}
              </span>
              <span style={{ color: "red" }}>{formik.values.quantity}</span> {pair.base}的订单
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                取消
              </Button>
              <Button
                onClick={() => {
                  onClose();
                  handleActualSubmit(formik.values);
                }}
                ml={3}
              >
                确定
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </FormikProvider>
  );
};

export default TpSlOrderFrom;
