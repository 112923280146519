import { Flex } from "@chakra-ui/react";
import { Formik } from "formik";
import { Form } from "react-router-dom";
import { strategiesApi } from "src/api";
import { StrategyResponse, StrategyType, UpdateStrategyParams, VolumeExampleType } from "src/api/types";
import { useExchangeInfoStore } from "src/store";
import CButton from "../CButton/CButton";
import styles from "./StrategyDetail.module.scss";
import * as Yup from "yup";
import { useExchangeAccounts } from "src/hook/useExchangeAccounts";
import { useMessage } from "src/hook/useMessage";
import CommonVolumeForm from "src/component/StrategyForms/CommonVolumeForm";
import CSwitch from "../CSwitch/CSwitch";
import { ErrorMessage } from "src/utils/ErrorMessage";

type Props = {
  strategy: StrategyResponse<VolumeExampleType>;
  onUpdated?: () => void;
};

export default function VolumeMakingDetail(props: Props) {
  const { strategy, onUpdated } = props;
  const message = useMessage();
  const accounts = useExchangeAccounts();
  const pairs = useExchangeInfoStore((state) => state.spotPairs);
  const pair = pairs.find((pair) => pair.id === strategy.pairId);
  const exchangeAccount = accounts.allExchangeAccounts.find((account) => account.id === strategy.exchangeAccountId);
  return (
    <Formik
      initialValues={{
        strategyName: strategy.strategyName,
        repeatIntervalMsMin: strategy.repeatIntervalMsMin,
        repeatIntervalMsMax: strategy.repeatIntervalMsMax,
        minBaseAmount: strategy.preference.minBaseAmount,
        maxBaseAmount: strategy.preference.maxBaseAmount,
        hourlyAmountLimit: strategy.preference.hourlyAmountLimit,
        refillBuyPert: strategy.preference.refillBuyPert,
        refillSellPert: strategy.preference.refillSellPert,
        refillLimit: strategy.preference.refillLimit,
        refillHourlyLimit: strategy.preference.refillHourlyLimit,
        buySellRand: strategy.preference.buySellRand,
        execRand: strategy.preference.execRand,
        rangeMin: strategy.preference.rangeMin,
        rangeMax: strategy.preference.rangeMax,
        priceGap1: strategy.preference.priceGap1,
        priceRand1: strategy.preference.priceRand1,
      }}
      validationSchema={Yup.object({
        strategyName: Yup.string().required("Required"),
      })}
      onSubmit={async (values) => {
        console.log("====================================");
        console.log("values", values);
        console.log("====================================");
        alert(JSON.stringify(values, null, 2));
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <Form className={styles.form}>
            <Flex width="calc(100% - 20px)" flexDirection="column" gap={2}>
              <CommonVolumeForm styles={styles} />
              <CSwitch
                label={"随机价格"}
                onChange={setFieldValue}
                description="开启的话，在卖1和买1之间随机价格。不开启则取中间价"
                isChecked={values.priceRand1}
                name="priceRand1"
              />
              <Flex alignItems="center" gap={2} mt={4}>
                <CButton
                  name="保存"
                  type="submit"
                  onClick={async () => {
                    const payload: UpdateStrategyParams<VolumeExampleType> = {
                      strategyId: strategy.id,
                      strategyName: values.strategyName,
                      validAfter: strategy.validAfter,
                      repeatIntervalMsMin: values.repeatIntervalMsMin,
                      repeatIntervalMsMax: values.repeatIntervalMsMax,
                      preference: {
                        type: StrategyType.VolumeMaking,
                        minBaseAmount: Number(values.minBaseAmount),
                        maxBaseAmount: Number(values.maxBaseAmount),
                        hourlyAmountLimit: Number(values.hourlyAmountLimit),
                        refillBuyPert: Number(values.refillBuyPert),
                        refillSellPert: Number(values.refillSellPert),
                        refillLimit: Number(values.refillLimit),
                        refillHourlyLimit: Number(values.refillHourlyLimit),
                        buySellRand: Number(values.buySellRand),
                        execRand: Number(values.execRand),
                        rangeMin: Number(values.rangeMin),
                        rangeMax: Number(values.rangeMax),
                        priceGap1: Number(values.priceGap1),
                        priceRand1: !!values.priceRand1,
                      },
                    };
                    try {
                      const result = await strategiesApi.updateVolumeStrategyV2(payload);
                      message.success("更新成功");
                      onUpdated && onUpdated();
                    } catch (error) {
                      message.detailedError(<ErrorMessage error={error} />);
                    }
                  }}
                />
              </Flex>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
}
