import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { strategiesApi } from "src/api";
import { CreateStrategyParams, StrategyKey, StrategyType, TradeDirection, TPSLExampleType } from "src/api/types";
import CButton from "src/component/CButton/CButton";
import CSelect from "src/component/CSelect";
import { useExchangeInfoStore } from "src/store";
import { getPairName } from "src/store/utils";
import * as Yup from "yup";
import styles from "./index.module.scss";
import { useState } from "react";
import { useExchangeAccounts } from "src/hook/useExchangeAccounts";
import React from "react";
import CInput from "src/component/CInput/CInput";

export default function TPSLAutoForm() {
  const store = useExchangeInfoStore();
  const exchanges = useExchangeAccounts();
  const [selectedExchangeAccountId, setSelectedExchangeAccountId] = useState<number | null>(null);

  const exchangeAccountOptions = exchanges.myExchangeAccounts.map((account) => {
    const exchange = store.exchanges.find((exchange) => exchange.id === account.exchangeId);
    const displayName = `${exchange ? exchange.name : "unknown"}: ${account.alias}`;
    return { value: account.id, label: displayName };
  });

  const pairOptions = store.spotPairs.map((pair) => ({
    value: pair.id,
    label: getPairName(pair.base, pair.quote),
  }));

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const sideOptions = [
    { value: TradeDirection.Buy, label: "买入" },
    { value: TradeDirection.Sell, label: "卖出" },
  ];

  return (
    <Formik
      initialValues={{
        strategyName: "",
        pair: "",
        exchangeAccount: "",
        repeatIntervalMsMin: "10000",
        repeatIntervalMsMax: "20000",
        preference: {
          type: "TriggerBuySell", // Default preference value
          triggerPrice10: "",
          buy10: true,
          price10: "",
          qta10: "",
        },
      }}
      validationSchema={Yup.object({
        pair: Yup.object().required("Required"),
        exchangeAccount: Yup.object().required("Required"),
        preference: Yup.object().shape({
          type: Yup.string().required("Required"),
          triggerPrice10: Yup.string().required("Required"),
          buy10: Yup.boolean().required("Required"),
          price10: Yup.number().required("Required"),
          qta10: Yup.number().required("Required"),
        }),
      })}
      onSubmit={async (values) => {
        const payload: CreateStrategyParams<TPSLExampleType> = {
          strategyName: values.strategyName,
          pairId: Number((values.pair as any).value),
          exchangeAccountId: Number((values.exchangeAccount as any).value),
          type: StrategyType.TPSLAuto,
          repeatIntervalMsMin: Number(values.repeatIntervalMsMin),
          repeatIntervalMsMax: Number(values.repeatIntervalMsMax),
          preference: {
            type: StrategyType.TPSLAuto,
            triggerPrice10: values.preference.triggerPrice10,
            buy10: values.preference.buy10,
            price10: Number(values.preference.price10),
            qta10: Number(values.preference.qta10),
          },
        };

        try {
          const result = await strategiesApi.createStrategyV2(payload);
          window.location.href = `/strategies?tab=${StrategyKey.TPSLAuto}`;
        } catch (error) {
          alert("创建失败");
        }
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <>
            <Form className={styles.form}>
              <Flex width="calc(100% - 20px)" flexDirection="column" gap={2}>
                <Flex alignItems="center" gap={2} mt={4}>
                  <CSelect
                    classname={styles.select}
                    label={"交易所账户"}
                    placeholder="交易所账户"
                    options={exchangeAccountOptions}
                    onChange={(value) => {
                      setFieldValue("exchangeAccount", value);
                      setSelectedExchangeAccountId((value as any).value);
                    }}
                  />
                </Flex>
                <Flex alignItems="center" gap={2} mt={2}>
                  <CSelect
                    classname={styles.select}
                    label={"交易对"}
                    placeholder="交易对"
                    options={pairOptions}
                    onChange={(value) => {
                      setFieldValue("pair", value);
                    }}
                  />
                </Flex>
                <Flex alignItems="center" gap={2} mt={2}>
                  <CSelect
                    classname={styles.select}
                    label={"交易方向"}
                    placeholder="交易方向"
                    options={sideOptions}
                    onChange={(value: { value: string; label: string }) => {
                      setFieldValue("preference.buy10", value.value === TradeDirection.Buy);
                    }}
                  />
                </Flex>

                <Flex alignItems="center" gap={2} mt={4}>
                  <CInput
                    type="text"
                    name="strategyName"
                    label="策略名称"
                    onChange={(e) => setFieldValue("strategyName", e.target.value)}
                  />
                </Flex>

                <Flex alignItems="center" gap={2} mt={4}>
                  <CInput
                    type="text"
                    name="preference.triggerPrice10"
                    label="触发价格"
                    onChange={(e) => setFieldValue("preference.triggerPrice10", e.target.value)}
                  />
                </Flex>

                <Flex alignItems="center" gap={2} mt={2}>
                  <CInput
                    type="number"
                    label="下单价格"
                    name="preference.price10"
                    onChange={(e) => setFieldValue("preference.price10", e.target.value)}
                  />
                </Flex>
                <Flex alignItems="center" gap={2} mt={2}>
                  <CInput
                    type="number"
                    name="preference.qta10"
                    label="下单数量"
                    onChange={(e) => setFieldValue("preference.qta10", e.target.value)}
                  />
                </Flex>
                <Flex>
                  <CInput
                    classname={styles.inputField}
                    label="重复间隔(最小值)"
                    name="repeatIntervalMsMin"
                    type="number"
                  />
                </Flex>

                <Flex>
                  <CInput
                    classname={styles.inputField}
                    label="重复间隔(最大值)"
                    name="repeatIntervalMsMax"
                    type="number"
                  />
                </Flex>

                <Flex alignItems="center" gap={2} mt={4}>
                  <CButton name="确认创建" onClick={onOpen} />
                </Flex>
              </Flex>
            </Form>

            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    确认创建
                  </AlertDialogHeader>

                  <AlertDialogBody>确定立即创建机器人吗？</AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      取消
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        submitForm();
                      }}
                      ml={3}
                    >
                      确定
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        );
      }}
    </Formik>
  );
}
